/* KakaoMap.css */

.map-container {
    position: relative;
    width: 75%;
    /* height: 50%; */
    height: 45vh;
    overflow: hidden; /* 지도 외부 요소가 잘리지 않도록 설정 */
    margin-bottom: 2vh;
}

#map {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* 지도 컨트롤 위치를 조정하기 위해 추가 */
#map .custom-control {
    position: absolute;
    top: 10px;
    right: 10px;
    transform: translate(-10px, 10px); /* 필요에 따라 위치 조정 */
}
/* KakaoMap.css */

/* fade-out 애니메이션 */
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* marker-animated 클래스로 fade-out 애니메이션 적용 */
.marker-fade-out {
    animation: fadeOut 1s forwards;
}

/* KakaoMap.css */

/* 팝인 애니메이션 정의 */
@keyframes popIn {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    60% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
  

  .overlay-animated {
    animation: popIn 0.5s ease forwards;
  }
  
  /* 오버레이마다 지연 시간을 주기 위한 클래스 (필요 시 추가) */
  .overlay-delay-1 {
    animation-delay: 0.1s;
  }
  
  .overlay-delay-2 {
    animation-delay: 0.2s;
  }
  
  .overlay-delay-3 {
    animation-delay: 0.3s;
  }
  
