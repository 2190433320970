.login_box{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center; 
    align-content: center;
    width: 700px;
    border: 1px solid #ccc;
    margin: auto auto;
    border-radius: 10px;
    height: 500px;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5); */
}

.bagel-fat-one-regular {
    font-family: "Bagel Fat One", system-ui;
    font-weight: 400;
    font-style: normal;
}
