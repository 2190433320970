html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #121212;
}
textarea,
input,
button {
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
  background-color: white;
  line-height: 1.5;
  margin: 0;
}
button {
  color: #3992ff;
  font-weight: 500;
}

textarea:hover,
input:hover,
button:hover {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
}

button:active {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.4);
  transform: translateY(1px);
}

#contact h1 {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
#contact h1 form {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
}
#contact h1 form button {
  box-shadow: none;
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0;
}
#contact h1 form button[value="true"] {
  color: #a4a4a4;
}
#contact h1 form button[value="true"]:hover,
#contact h1 form button[value="false"] {
  color: #eeb004;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
}

#sidebar {
  width: 17%;
  height: 100%;
  background-color: #f7f7f7;
  border-right: solid 1px #eee;
  display: flex;
  flex-direction: column;
  position: fixed;
}

#sidebar > * {
  padding-left: 2rem;
  padding-right: 2rem;
}

#sidebar h1 {
  font-size: 1.5rem;
  font-weight: 1000;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 2rem 2rem;
  border-bottom: 1px solid #e3e3e3;
  order: 0;
  line-height: 1;
}

/* #sidebar h1::before {
  content: url("");
  margin-right: 0.5rem;
  position: relative;
  top: 1px;
} */

#sidebar > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 15%;
  /* gap: 0.5rem; */
  /* padding-top: 1rem;*/
  padding-bottom: 1rem; 
  /* border-bottom: 1px solid #e3e3e3; */
}

#sidebar > div button {
  width: 100%;
  position: relative;
  }
/* 

#sidebar > div form input[type="search"] {
  width: 100%;
  padding-left: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='%23999' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 0.625rem 0.75rem;
  background-size: 1rem;
  position: relative;
}

#sidebar > div form input[type="search"].loading {
  background-image: none;
}

#search-spinner {
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M20 4v5h-.582m0 0a8.001 8.001 0 00-15.356 2m15.356-2H15M4 20v-5h.581m0 0a8.003 8.003 0 0015.357-2M4.581 15H9' /%3E%3C/svg%3E");
  animation: spin 1s infinite linear;
  position: absolute;
  left: 0.625rem;
  top: 0.75rem;
} */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Bagel+Fat+One&display=swap');

.bagel-fat-one-regular {
    font-family: "Bagel Fat One", system-ui;
    font-weight: 400;
    font-style: normal;
}

@font-face {
  font-family: 'HSSanTokki20-Regular';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2405@1.0/HSSanTokki20-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NPSfontBold';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2310@1.0/NPSfontBold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: '양진체';
  src: url('https://fastly.jsdelivr.net/gh/supernovice-lab/font@0.9/yangjin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'iceHimchan-Rg';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2307-2@1.0/iceHimchan-Rg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'STUNNING-Bd';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2410-2@1.0/STUNNING-Bd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GapyeongWave';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2312-1@1.1/GapyeongWave.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'omyu_pretty';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2304-01@1.0/omyu_pretty.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ownglyph_meetme-Rg';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2402_1@1.0/Ownglyph_meetme-Rg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DungGeunMo';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/DungGeunMo.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.logo {
  width: 80px;
  height: auto;
}

/* Flexbox 컨테이너 설정 */
.container {
  display: flex;
  flex: 1;
  padding-left: 17%;
  flex-direction: column;
  min-height: 110vh; /* 전체 화면 높이 */
}

/* main이 남은 공간을 차지하도록 */
main {
  flex: 1;
  padding: 1rem;
  /* position: relative; */
  /* border-bottom: 1px solid #ccc;  */
}

/* Footer 스타일 */
footer {
  /* border-top: 1px solid #ccc; */
  /* background-color: #333; */
  box-shadow: inset 0 1px 0 #ccc;
  color: white;
  text-align: center;
  padding: 1rem;
}

#sidebar nav {
  flex: 1;
  overflow: auto;
  padding-top: 1rem;
  /* font-family: "Bagel Fat One", system-ui; */
  /* font-family: 'HSSanTokki20-Regular', system-ui; */
  font-family: 'NPSfontBold', system-ui;
  /* font-family: 'iceHimchan-Rg', system-ui; */
  /* font-family: '양진체', system-ui; */
  /* font-family: 'STUNNING-Bd', system-ui; */
  /* font-family: 'GapyeongWave', system-ui; */
  /* font-family: 'omyu_pretty', system-ui; */
  /* font-family: 'Ownglyph_meetme-Rg', system-ui; */
  /* font-family: 'DungGeunMo', system-ui; */
  /* position: fixed; */
}

#sidebar nav a span {
  float: right;
  color: #eeb004;
}
#sidebar nav a.active span {
  color: inherit;
}

i {
  color: #818181;
}
#sidebar nav .active i {
  color: inherit;
}

#sidebar ul {
  padding: 0;
  margin: 0;

  list-style: none;
}

#sidebar li {
  margin: 1rem 0;
}

#sidebar nav a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  /* width: 100%; */
  white-space: pre;
  padding: 1rem;
  border-radius: 8px;
  color: inherit;
  font-size: 3vh;
  text-decoration: none;
  gap: 1rem;
}

#sidebar nav a:hover {
  background: #e3e3e3;
}

#sidebar nav a.active {
  background: #90B54C;
  color: white;
}

#sidebar nav a.pending {
  color: #90B54C;
}

/* logout logo */
.logout {
  transition: transform 0.1s;
}


.logout:hover {
  transform: scale(1.1); /* hover 시 확대 */
}

#detail {
  flex: 1;
  padding: 6rem 3% 0 3%;
  width: 100%;
  /* background-color: #eee; */
  /* overflow-y: scroll; */
}

#detail.loading {
  opacity: 0.25;
  transition: opacity 200ms;
  transition-delay: 200ms;
}



#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}