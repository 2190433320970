.info-title {
    display: inline-block; /* 중앙 정렬 시 블록요소를 인라인 블록으로 변환 */
    background: #fff;
    color: #90B54C;
    text-align: center;
    height: 3vh;
    box-shadow: 0 0 0 2px #90B54C;
    /* border: 3px solid #90B54C; */
    line-height: 3vh; /* 높이와 일치시켜 텍스트 수직 중앙 정렬 */
    border-radius: 4px;
    padding: 0 10px;
    font-family: 'NPSfontBold', system-ui;
    /* margin: 0 auto;  */
    margin-top: 3vh;
    font-size: 2vh;
}